/*
 * @Author: mengxiande
 * @LastEditors: Please set LastEditors
 * @email: 1104252528@qq.com
 * @github: https://github.com/mengxiaodi
 * @Date: 2023-09-13 13:32:41
 * @LastEditTime: 2023-09-13 16:45:36
 * @Description: ...
 */
import Vue from 'vue'
import App from './App.vue'
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { RadioGroup, Radio, CellGroup } from 'vant';
import 'vant/lib/index.css';


import 'lib-flexible/flexible.js';

import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;


Vue.config.productionTip = false
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(CellGroup);

new Vue({
  render: h => h(App),
}).$mount('#app')
