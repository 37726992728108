<!--
 * @Author: mengxiande
 * @LastEditors: Please set LastEditors
 * @email: 1104252528@qq.com
 * @github: https://github.com/mengxiaodi
 * @Date: 2023-09-13 13:32:41
 * @LastEditTime: 2023-09-14 18:33:22
 * @Description: ...
-->
<template>
  <div id="app">
    <IndexPage />
    <!-- <ConfigHandler /> -->
  </div>
</template>

<script>
import IndexPage from './components/indexComponent.vue'
// import ConfigHandler from './components/configHandler.vue'

export default {
  name: 'App',
  components: {
    IndexPage: IndexPage,
    // ConfigHandler: ConfigHandler
  }
}
</script>

<style>
body{
  padding:0;
  margin: 0;
}
#app {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
