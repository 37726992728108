<!--
 * @Author: mengxiande
 * @LastEditors: Please set LastEditors
 * @email: 1104252528@qq.com
 * @github: https://github.com/mengxiaodi
 * @Date: 2023-08-25 19:44:34
 * @LastEditTime: 2023-09-25 17:50:51
 * @Description: ...
-->
<template>
  <div class="content">
    <div class="banner">
      <h3>陪诊师招募</h3>
      <p>自由、轻松、赚钱快</p>
    </div>
    <div class="form-box">
      <div class="news">
        橙医健康是全国第一家民用医疗级《高级陪诊师》服务机构，为顾客提供专业的陪诊服务，解决顾客没时间陪家人看病的棘手问题，包括挂号、缴费、取药、打印病历、邮寄药品、办理出入院等一系列陪诊服务。除此之外，陪诊师还会对患者进行综合分析，为患者制定最佳的就医方案和建议，帮助患者节省时间和精力，提高就医效率。

      </div>
      <div class="video-box">
        <video id="myVideo" ref="myVideo" height="100" @play="playingVideo" @pause="pauseVideo" controls class="video-js vjs-big-play-centered vjs-fluid" >
          <source :src="videoSrc" type="video/mp4">
        </video>
      </div>
      <div class="video-box-outer">
        <div class="video-box-hander">
          <div class="video-list-box">
            <div v-for="item in videoListOrigin" :class="item.id===playIndex && isPlaying ? 'video-list playing' : 'video-list'" @click="playVideo(item.id)" :key="item.id">
              <img :src="item.img" />
              <span class="handler"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-title">
        报名咨询
      </div>
      <van-form @submit="onSubmit">
        <van-cell-group inset class="radio-box" name="asdfasd">
          <van-field
            class="input-box"
            v-model="formData.name"
            name="姓名"
            autocomplete="off"
            label="姓名:"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          >
            <slot name="title">姓名</slot>
          </van-field>
          <van-field
            v-model="formData.sex"
            name="性别"
            autocomplete="off"
            label="性别:"
            :rules="[{ required: true, message: '请选择性别' }]"
          >
          <template #input>
            <van-radio-group v-model="formData.sex" name="性别">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
          </van-field>
          <van-field
            autocomplete="off"
            class="input-box"
            v-model="formData.mobile"
            name="手机号"
            label="手机号:"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '请输入手机号' }]"
          />
          <van-field
            autocomplete="off"
            class="input-box"
            v-model="formData.address"
            name="地区"
            label="地区:"
            placeholder="请输入地区"
          />
          <van-field
          class="input-box"
          autocomplete="off"
            v-model="formData.education"
            name="学历"
            label="学历:"
            placeholder="请输入学历"
          />
          <van-field
            class="input-box"
            v-model="formData.age"
            name="年龄"
            autocomplete="off"
            label="年龄:"
            placeholder="请输入年龄"
          />
          <van-field
            class="input-box"
            v-model="formData.shareCode"
            name="邀请码"
            autocomplete="off"
            length="11"
            label="邀请码:"
            placeholder="请输入邀请码"
          />
        </van-cell-group>
        <div class="submit-button">
          <van-button  type="primary" native-type="submit">
            提交
          </van-button>
          <van-button class="cancel" type="primary" @click="reset">
            重写
          </van-button>
        </div>
      </van-form>

    </div>

  </div>
</template>

<script>

import axios from 'axios';
import { Toast } from 'vant';

import 'video.js/dist/video-js.css';
import videojs  from 'video.js';
var wx = require('weixin-js-sdk');



export default {
  components:{

  },
  data() {
    return {

      videoSrc: '',
      title: '橙医陪诊',
      initFormData: {},
      videoListOrigin:[
        {
          id:0,
          src:'https://wap.ceedoctor.com/video/zhuan_ye_pei_zhen.mov',
          img:'https://wap.ceedoctor.com/zhuan_ye_pei_zhen.png'
        },
        {
          id:1,
          src:'https://wap.ceedoctor.com/video/vip_pei_zhen.mov',
          img:'https://wap.ceedoctor.com/vip.png'
        }
      ],
      formData:{
        name: undefined,
        sex:1,
        mobile: undefined,
        education: undefined,
        age: undefined,
        address: undefined,
        shareCode:null
      },
      isPlaying:false,
      checked: false,
      vstart:0,
      vend:0,
      vpause:0,
      playIndex:null,
    }
  },
  created(){
    this.wxConfig();
    this.videoSrc = this.videoListOrigin[0].src;
  },
  mounted(){
    let videojs_player = null;
    this.player = videojs(this.$refs.myVideo, {
      controls: true,
      preload: 'auto',
      controlBar: {
          playToggle: {
              replay: true
          },
          progressControl: true
      }
    }, function(){
      let video = document.getElementById('myVideo_html5_api');
      video.onclick = function(){
        console.log(2);
      }
      videojs_player = this;
      videojs_player.on("click",function(){
        console.log('click video');
      })

    });

    // this.player.addChild('bigbigbigbigbigbig');
    // if(wx){

    // }
  },
  beforeDestroy(){
    if(this.player){
      this.player.dispose();
    }
  },
  methods: {
    pauseVideo(){
      this.isPlaying = false;
    },
    playingVideo(e){
      this.isPlaying = true;
      console.log(this.$refs.myVideo.src)
    },
    myClickHandler(){
      console.log(23);
    },
    playVideo(index){
      console.log(index);
      if(index === this.playIndex && this.isPlaying){
        console.log('isplaying');
        this.player.pause();
        this.isPlaying = false;
        this.playIndex = null;
        return;
      }
      const src = this.videoListOrigin[index].src;
      this.player.pause();
      this.videoSrc = src;
      this.player.src([{src:src}]);
      this.player.load();
      this.player.play();
      this.isPlaying = true;
      this.playIndex = index;

      // this.player.preload();
      // this.player.play();

    },
    clickFn(){},
    wxConfig(){
      axios.post('/shareConfig').then(res=>{
        var resData = res.data;
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: resData.appId, // 必填，公众号的唯一标识
            timestamp: resData.timestamp, // 必填，生成签名的时间戳
            nonceStr: resData.nonceStr, // 必填，生成签名的随机串
            signature: resData.signature,// 必填，签名
            jsApiList: [
                'menuItem:share:appMessage',
                'menuItem:share:timeline',
                'menuItem:share:qq',
                'menuItem:share:QZone',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'onMenuShareQQ',
                'onMenuShareWeibo'
                ] // 必填，需要使用的JS接口列表
        });
        wx.error(function(res){
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            // alert(JSON.stringify(res));
        });
        wx.ready(function(){
            wx.updateTimelineShareData({
              title: '橙医陪诊招募', // 分享标题
              link: 'https://pzs.ceedoctor.com/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: 'https://mmbiz.qpic.cn/mmbiz_jpg/rW5tUb2kBuQ8wUabLl01hsoAnu9daeZ3KIRXtqbKSPxpjPSOU1d4cHHQIpJT9QVhCaInJZeIRiaoFP6fgc9vX1w/0?wx_fmt=jpeg', // 分享图标
              success: function () {
                // 设置成功
              }
            });
            wx.onMenuShareTimeline({
                title: '橙医陪诊招募', // 分享标题
                link: 'https://pzs.ceedoctor.com/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://mmbiz.qpic.cn/mmbiz_jpg/rW5tUb2kBuQ8wUabLl01hsoAnu9daeZ3KIRXtqbKSPxpjPSOU1d4cHHQIpJT9QVhCaInJZeIRiaoFP6fgc9vX1w/0?wx_fmt=jpeg', // 分享图标
                success: function () {
                    // 用户点击了分享后执行的回调函数
                    // alert("分享朋友圈成功");
                },
                error: function(){
                    // alert('分享失败');
                }
            });
            wx.onMenuShareAppMessage({
                title: '橙医陪诊招募', // 分享标题
                desc: '橙医陪诊招募,欢迎你来加入', // 分享描述
                link: 'https://pzs.ceedoctor.com/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://mmbiz.qpic.cn/mmbiz_jpg/rW5tUb2kBuQ8wUabLl01hsoAnu9daeZ3KIRXtqbKSPxpjPSOU1d4cHHQIpJT9QVhCaInJZeIRiaoFP6fgc9vX1w/0?wx_fmt=jpeg', // 分享图标
                type: 'link', // 分享类型,music、video或link，不填默认为link
                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                success: function () {
                    // alert('分享到朋友成功'); 
                    // 用户点击了分享后执行的回调函数
                }
            });
        });
      });

    },
    pause(){
      // console.log('pause');
      this.vpause = new Date().getTime();
    },
    ended(){
      // console.log('ended');
      this.vend = new Date().getTime();
    },
    start(){
      // console.log('startxs');
      this.vstart = new Date().getTime();
    },

    onSubmit(){
      this.submit();
    },
    reset(){
      this.formData = { ...this.initFormData}
      console.log(this.formData);
    },
    submit(){
      const url = '/addUser';
      // const url = '/shareConfig';
      axios.post(url,{...this.formData}).then(res=>{
        if(res.data.success){
          Toast('提交成功，我们会在48小时内联系您!');
          this.formData = { ...this.initFormData }
        }
      });
    }
  },
}
</script>

<style lang="less">
body{
  background:#EB4706;
}

.uni-video-bar{
  background-color: rgba(0,0,0,.0);
}
.van-field__value{
  width:calc(100% - 60px)
}
.van-field__control{
  width: 80%;

}
.van-field__body input:-internal-autofill-previewed,
.van-field__body input:-internal-autofill-selected{
  background-color:transparent !important;
}
.van-form{
  background:#fff;
}
.van-radio-group{
  font-size: 14px;
  display: flex;
}
.content{

  max-width: 750px;
  margin:0px auto;
  width: 100%;
  font-size: 28px;
  .banner{
    overflow: hidden;
    img{
      width:100%;
    }
  }
}

.van-cell-group--inset{
  padding:20px 0;
}
.form-box{
  padding:20px 0;
  margin: 16px auto;
  width: 375px;
  // .form-list{
  //   display: flex;
  //   width: 100%;
  //   height: 37px;
  //   line-height: 37px;
  //   margin-top: 27px;
  // }
  .van-field__value{
    position: relative;
  }
  .van-field__body{
    height:100%;

  }
  .van-cell{
    padding-top: 0;
    padding-bottom: 0;
  }
  .van-field__error-message{
    position: absolute;
    top:10px;
    display: none;
  }
  .van-field{
    display: flex;
    align-items: center;
  }
  .van-field__label{
    margin-right: 18px;
    width:60px;
    text-align: right;
    color:#666;
    font-size: 14px;
    line-height: 60px;
    height: 60px;
    span {
      color:#333;
    }
  }
  .form-input{
    width: 100%;
    display: flex;
    min-height: 37px;
    input{
      padding:0px 10px;
      border-radius: 5px;
      width:100%;
      box-sizing: border-box;
      height:37px;
      line-height: 37px;
      border:1px solid #D0CECE;
      font-size: 16px;
      color:#333;
    }
  }
}
.van-radio{
  margin-right: 20px;
}
.van-radio__icon--checked .van-icon{
  background-color: #EB4706 !important;
  border-color: #EB4706 !important;
}
.submit-button{
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px auto;
  .van-button{
    display: block;
    width: 156px;
    height: 38px;
    line-height: 38px;
    border-radius: 6px;
    opacity: 1;
    background: #EB4706;
    text-align: center;
    margin-top: 10px;
    color:#fff;
    border: none;
  }
  .cancel{
    background:none;
    border:1px solid #EB4706;
    color:#EB4706;
  }
}
.van-cell:after{
  border:none;
}

.van-form{
  width: 350px;
  margin: 0px auto;
  border-radius: 10px;
  overflow: hidden;


}


.van-field__label{
  width:60px;
  line-height: 37px;
  height: 37px;
  text-align: right;
}
.video-box-hander{
  width: 100%;
  padding:0px 10px 10px;
  margin: 10px auto 20px;
  overflow: scroll;
}
.video-list-box{
  width:500px;
  padding-left:10px;
}
.video-box-outer{
  height:115px;
  overflow: hidden;
  margin-bottom: 20px;
}
.video-box-hander .video-list{
  width: 150px;
  height: 100px;
  line-height: 100px;
  overflow: hidden;
  background:#fcc;
  margin:0px 5px;
  border-radius: 5px;
  display: inline-block;
  position:relative;
}
.video-box-hander .video-list img{
  width:100%;
  height:100px;
  object-fit: cover;
}
.video-box-hander .video-list .handler{
  width:100%;
  height:100%;
  position: absolute;
  z-index: 9;
  left:0;
  top:0;
  bottom: 0;
  right:0;
  background: url(../static/play.svg) no-repeat rgba(0,0,0,.25);
  background-size: 30px auto;
  background-position: center center;
}
.video-box-hander .video-list.playing .handler{
  background: url(../static/pause.svg) no-repeat rgba(0,0,0,.25);
  background-size: 30px auto;
  background-position: center center;
}
.news{
  line-height: 1.8em;
  margin:0px auto;
  text-align: justify;
  width:300px;
  font-size: 15px;
  color: #fff;
  padding:15px 20px;
  background: rgba(255,255,255,.1);
  border-radius: 10px;
  border: 1px solid rgba(255,255,255,.3);
}

.vjs-modal-dialog .vjs-modal-dialog-content, .video-js .vjs-modal-dialog, .vjs-button > .vjs-icon-placeholder:before, .video-js .vjs-big-play-button .vjs-icon-placeholder:before{
  position: relative !important;
  top:2px !important;
  left:1px  !important;

}
.video-js .vjs-picture-in-picture-control{
  display: none !important;
}
.video-js .vjs-big-play-button{
  width:50px;
  height:50px;
  margin-left: -25px;
  margin-top: -25px;
  border-radius: 100px;
}
.video-box{
  text-align: center;
  position: relative;
  background:#000;
  margin:20px auto 0;
  width:94%;
  height: 200px;
  overflow: hidden;
  border-radius:10px;
}
.myVideo-dimensions.vjs-fluid:not(.vjs-audio-only-mode){
  padding: 0;
}
.video-js.vjs-fluid{
  height: 200px !important;
}
.video-box video{
  height: 200px !important;
}
.uni-video-controls{
  // display: none;
}
.video-box{
  .play-btn{
    position: absolute;
    width:30px;
    height:30px;
    left:50%;
    top:50%;
    z-index: 99999;
    // background:#fcc;
    transform: translate3d(-50%, -50%, 0);
  }
}
.banner{
  text-align: center;
  background: url(../static/bg.jpg) no-repeat;
  background-size: 100% auto;
  h3{
    padding-top: 30px;
    font-size: 39px;
    font-weight: bold;
    color:#fff;
    margin-bottom: 0px;
  }
  p{
    font-size: 18px;
    color:#fff;
  }
}
.form-title{
  font-size: 24px;
  color:#fff;
  font-weight: bold;
  // text-align: center;
  padding: 10px;
  margin: 0px 10px;
}
.myVideo-dimensions{
  width: 100%;
}
.myVideo-dimensions{
  height: auto;
}
.video-js.vjs-playing .vjs-tech {
    pointer-events: auto;
}
</style>
